body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1d25;
  overflow-x: hidden;
}

.App {
  overflow: hidden;
  min-height: 80vh;
  padding-bottom: 40px;
}

* {
  font-family: "Poppins", sans-serif;
  color: #f6f6f6;

}

a {
  text-decoration: none;
}

@media only screen and (min-width: 820px) {
  * {
    font-size: 1.3rem;
  }

  .App {
    padding-bottom: 0;
    min-height: 100vh;
  }
}